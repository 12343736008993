<template>
  <div class="">
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Статус</label>
            <v-select
              v-model="filter.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="filterStatusOptions"
              class="w-100"
              :reduce="val => val.id"
              label="name"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <list-crud
      :loading="loading"
      :per-page.sync="perPage"
      :per-page-options="perPageOptions"
      :search-query.sync="searchQuery"
      :items="items"
      :table-columns="tableColumns"
      :sort-by.sync="sortBy"
      :is-sort-dir-desc.sync="isSortDirDesc"
      :current-page.sync="currentPage"
      :from="from"
      :to="to"
      :total-items="totalItems"
      hide-search
      @click-btn-create="createAndShowOrder"
    >
      <template #cell(number)="data">
        <b-link
          :to="{
            name: 'order.show',
            params: { id: data.item.id, type: data.item.source === 'employee' ? 'default': data.item.source},
          }"
        >{{ data.item.number }}</b-link>
      </template>
      <template #cell(items)="data">
        {{ getCountItems(data.item) }}
      </template>
      <template #cell(total)="data">
        {{ (getTotal(data.item)).toLocaleString() }} ₽
      </template>
      <template #cell(person_id)="data">
        <div
          v-if="data.item.person"
          class=""
        >
          <div class="strong small">
            {{ get(data.item, 'person.surname', '') }} {{ get(data.item, 'person.name', '') }} {{ get(data.item, 'person.patronymic', '') }}
          </div>
          <a
            v-if="get(data.item, 'person.phone', '')"
            target="_blank"
            :href="`tel:${get(data.item, 'person.phone', '')}`"
          >
            {{ get(data.item, 'person.phone', '') }}
          </a>
        </div>
        <div
          v-else
          class=""
        >
          -
        </div>
      </template>
      <template #cell(status_id)="data">
        {{ get(statuses.find(i => i.id === data.item.status_id), 'name') }}
      </template>
      <template #cell(source)="data">
        <div v-if="data.item.source === 'employee'">
          Создан сотрудником
        </div>
        <div v-else-if="data.item.source === 'website_external'">
          Внешний сайт
        </div>
        <div v-else-if="data.item.source === 'website_store'">
          Сайт магазин
        </div>
        <div
          v-else-if="data.item.source === 'yandex_market_fbs'"
        >
          Яндекс Маркет FBS
          <small class="d-block text-secondary">
            ID Интеграции: {{ data.item.source_id }}
          </small>
        </div>
        <div
          v-else-if="data.item.source === 'yandex_market_dbs'"
        >
          Яндекс Маркет DBS
          <small class="d-block text-secondary">
            ID Интеграции: {{ data.item.source_id }}
          </small>
        </div>
        <div
          v-else-if="data.item.source === 'merlion_fresh_ymfbs'"
        >
          Merlion ЯндексFBS FreshLogic
          <small class="d-block text-secondary">
            ID Интеграции: {{ data.item.source_id }}
          </small>
        </div>
        <div
          v-else-if="data.item.source === 'merlion_ymdbs'"
        >
          Merlion ЯндексDBS
          <small class="d-block text-secondary">
            ID Интеграции: {{ data.item.source_id }}
          </small>
        </div>
        <div
          v-else
        >
          {{ data.item.source }}
        </div>
        <b-badge
          v-if="data.item.is_test"
          pill
          style="margin-top: 3px;"
        >
          Тестовый
        </b-badge>
      </template>
    </list-crud>
  </div>

</template>

<script>
import {
  getCurrentInstance, onMounted, ref, computed, reactive, watch,
} from 'vue'
import get from 'lodash/get'
import {
  BCard, BCardBody, BRow, BCol, BLink, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useCrudList from '@/composables/useCrudList'
import { getOrders, deleteOrder, createOrder } from '@/services/main-api/shop/orders/orders'
import ListCrud from '@/components/crud/ListCrud.vue'
import { getStatuses as getStatusesApi } from '@/services/main-api/shop/statuses'

export default {
  name: 'ListView',
  components: {
    ListCrud,
    BCard,
    BCardBody,
    BRow,
    BCol,
    vSelect,
    BLink,
    BBadge,
  },
  setup() {
    const instance = getCurrentInstance().proxy

    // Статусы
    const statuses = ref([])
    const getStatuses = async () => {
      try {
        const res = await getStatusesApi({
          project: instance.$store.getters['workingMode/selected_project_id'],
          perPage: 100,
          orderBy: 'sort_order',
          sortedBy: 'asc',
        })

        statuses.value = res.data.data
      } catch (e) {
        statuses.value = []
      }
    }

    // Фильтры
    const filter = reactive({
      status: null,
    })
    const filterStatusOptions = computed(() => {
      const options = [
        { id: null, name: 'Все' },
      ]

      if (statuses.value.length) {
        options.push(...statuses.value)
      }

      return options
    })

    const getItems = async () => {
      let search = null

      if (filter.status) {
        search = `status_id:${filter.status}`
      }

      try {
        const res = await getOrders({
          project: instance.$store.getters['workingMode/selected_project_id'],
          perPage: instance.perPage,
          sortedBy: instance.isSortDirDesc ? 'desc' : 'asc',
          orderBy: instance.sortBy,
          page: instance.currentPage,
          search,
          includes: 'order_product_items;person',
        })

        instance.items = res.data.data
        instance.currentPage = res.data.meta.current_page
        instance.from = res.data.meta.from
        instance.to = res.data.meta.to
        instance.totalItems = res.data.meta.total
      } catch (e) {
        instance.items = []
      }
    }

    const {
      loading,

      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,

      sortBy,
      searchQuery,
      isSortDirDesc,
      tableColumns,

      items,
      deleteItem,
    } = useCrudList({
      sortBy: 'created_at',
      isSortDirDesc: true,
      tableColumns: [
        { key: 'number', label: 'Номер', sortable: true },
        { key: 'status_id', label: 'Статус', sortable: true },
        { key: 'source', label: 'Источник' },
        { key: 'person_id', label: 'Контакт' },
        { key: 'items', label: 'Товаров' },
        { key: 'total', label: 'Сумма' },
        { key: 'created_at', label: 'Создан', sortable: true },
      ],
      getItems,
      getMoreData: async () => {
        await getStatuses()
      },
      deleteEntity: item => deleteOrder(item.id),
      getQuestionTextBeforeDeletion: item => `Заказ ${item.name} будет удален`,
      getTextAfterDeletion: () => 'Заказ был удален.',
    })

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        instance.$router.push({ name: 'dashboard' })
      }
    })

    watch(() => filter.status, async () => {
      loading.value = true
      await getItems()
      loading.value = false
    })

    const createAndShowOrder = async () => {
      loading.value = true
      try {
        const res = await createOrder({
          project_id: instance.$store.getters['workingMode/selected_project_id'],
          status_id: 3, // в обработке
        })

        const id = get(res, 'data.data.id')
        instance.$router.push({
          name: 'order.show',
          params: { id, type: 'default' },
        })
      } catch (e) {
        // ...
      }
      loading.value = false
    }

    const getCountItems = order => {
      let count = 0
      if (order.source === 'employee' || order.source === 'website_external' || order.source === 'website_store') {
        count = get(order, 'order_product_items', []).reduce((sum, item) => sum + item.count, 0)
      } else if (order.source === 'merlion_fresh_ymfbs' || order.source === 'yandex_market_fbs' || order.source === 'yandex_market_dbs' || order.source === 'merlion_ymdbs') {
        count = get(order, 'external_data.items', []).reduce((sum, item) => sum + item.count, 0)
      }
      return count
    }
    const getTotal = order => {
      let total = 0
      if (order.source === 'employee' || order.source === 'website_external' || order.source === 'website_store') {
        total = get(order, 'order_product_items', []).reduce((sum, item) => sum + (item.price * item.count), 0)
      } else if (order.source === 'merlion_fresh_ymfbs' || order.source === 'yandex_market_fbs' || order.source === 'yandex_market_dbs' || order.source === 'merlion_ymdbs') {
        total = get(order, 'external_data.totalWithSubsidy', 0)
      }
      return total
    }

    return {
      loading,

      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,

      sortBy,
      searchQuery,
      isSortDirDesc,
      tableColumns,

      items,
      deleteItem,

      statuses,
      filter,
      filterStatusOptions,

      get,

      getCountItems,
      getTotal,

      createAndShowOrder,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
