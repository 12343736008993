import axiosIns from '@/libs/axios'

export function getStatus({ id, project }) {
  return axiosIns.get(`/shop/statuses/${id}`, {
    params: {
      project,
    },
  })
}

export function getStatuses({
  project, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes,
}) {
  return axiosIns.get('/shop/statuses', {
    params: {
      project,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
    },
  })
}

export default {
  getStatus,
  getStatuses,
}
